import type React from 'react'
import { useEffect, useState } from 'react'
import { type ColorVariant, colorVariantClasses } from '../ColorVariant/variant'

type SubmitButtonProps = {
  colorVariant?: ColorVariant
  bg?: string // 背景色のクラス名
  icon?: string // FontAwesomeのアイコンクラス名
  label: string // ボタンのラベル
  className?: string // オプションでclassNameを受け取る
  largeSize?: boolean
  loading: boolean // 送信中の状態を表す
  onClick?: () => void // ボタンがクリックされたときの処理
  disabled?: boolean
  tabIndex?: number
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  colorVariant = '',
  bg,
  icon,
  label,
  largeSize = false,
  loading,
  onClick,
  className,
  disabled = false,
  tabIndex = 0,
}) => {
  const [isKeyboardFocus, setIsKeyboardFocus] = useState(false)
  const colorVariantClass =
    colorVariantClasses[disabled ? 'disabled' : (colorVariant as ColorVariant)]

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        setIsKeyboardFocus(true)
      }
    }

    const handleMouseDown = () => {
      setIsKeyboardFocus(false)
    }

    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('mousedown', handleMouseDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [])

  return (
    <>
      {!loading ? (
        <button
          type='submit'
          className={`${
            !colorVariantClass ? bg : ''
          } inline-flex items-center justify-center gap-2 rounded-[10px] ${className} ${colorVariantClass} ${
            largeSize ? 'px-4 py-3.5 text-[15px]' : 'px-3 py-2.5 text-sm'
          } ${isKeyboardFocus ? 'focus-keyboard' : ''}`}
          onClick={onClick}
          disabled={loading}
          tabIndex={disabled ? -1 : tabIndex}
        >
          {icon && (
            <span
              className={`${icon} relative flex items-center justify-center icon-before text-[14px] w-[14px] h-[14px]`}
            />
          )}
          <span className='flex items-center font-semibold pt-0.5'>
            {label}
          </span>
        </button>
      ) : (
        <button
          type='submit'
          className={`inline-flex items-center justify-center gap-2 rounded-[10px] ${className} !bg-gray-5 !text-gray-3 ${
            largeSize ? 'px-4 py-3.5 text-[15px]' : 'px-3 py-2.5 text-sm'
          } ${isKeyboardFocus ? 'focus-keyboard' : ''}`}
          onClick={onClick}
          disabled={loading}
          tabIndex={disabled ? -1 : tabIndex}
        >
          <span className='icon-solid icon-spinner-third fa-spin relative flex items-center justify-center icon-before text-[14px] w-[14px] h-[14px]' />
          <span className='flex items-center font-semibold pt-0.5'>
            {label}
          </span>
        </button>
      )}
    </>
  )
}

export default SubmitButton
